.project {
    width: fit-content;
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.project-image img {
    border-radius: 10px;
    margin: 0 1em;
    width: 20rem;
}

.project-details {
    max-width: 20em;
    margin: 0 1em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
}

.project-title {
    /* font-family: "SemiBold", sans-serif; */
    font-family: "Heading-Bold", sans-serif;
    font-size: 1.5rem;
    line-height: 1.5;
}

.project-intro {
    font-family: "Light", sans-serif;
    font-size: 1rem;
    line-height: 1.8;
}

.dark-mode .project-link img {
    filter: invert(100%);
}

@media only screen and (max-width: 800px) {
    .project {
        flex-direction: column;
    }

    .project-image img {
        width: 90%;
    }

    .project-details {
        text-align: center;
        padding: 1rem 0;
    }
}
