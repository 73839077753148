/* 
Text: Aileron
Heading: Aileron
*/

/*
@font-face {
    font-family: "Regular";
    src: local("Aileron-Regular"),
        url("./assets/fonts/Aileron-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Regular-Italic";
    src: local("Aileron-Italic"),
        url("./assets/fonts/Aileron-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Light";
    src: local("Aileron-Light"),
        url("./assets/fonts/Aileron-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Thin";
    src: local("Aileron-Thin"),
        url("./assets/fonts/Aileron-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "SemiBold";
    src: local("Aileron-SemiBold"),
        url("./assets/fonts/Aileron-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Bold";
    src: local("Aileron-Bold"),
        url("./assets/fonts/Aileron-Bold.ttf") format("truetype");
} */

/* 
Text: Aktiv Grotesk 
Heading: GT Super
*/

@font-face {
    font-family: "Regular";
    src: url("./assets/fonts/new/Text-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Regular-Italic";
    src: url("./assets/fonts/new/Text-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Light";
    src: url("./assets/fonts/new/Text-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Thin";
    src: url("./assets/fonts/new/Text-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "SemiBold";
    src: url("./assets/fonts/new/Text-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Bold";
    src: url("./assets/fonts/new/Text-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Heading-Medium";
    src: url("./assets/fonts/new/Heading-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Heading-SemiBold";
    src: url("./assets/fonts/new/Heading-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "Heading-Bold";
    src: url("./assets/fonts/new/Heading-Bold.otf") format("opentype");
}

:root {
    --dark-red: #e54b4b;
    --project-yellow: #f3ec78;
    --salmon: #ffa987;
    --light-pink: #fbcab8;
    --pinkish-white: #f9dbd0;
    --cream-white: #f7ebe8;
    --dark-blue: #219ebc;
    --light-gray: #444140;
    --gray: #313032;
    --dark-gray: #1e1e24;
}

.fixed-button {
    position: fixed;
    left: 90vw;
    top: 50%;
    transform: rotate(90deg);
}

html {
    scroll-behavior: smooth;
    /* Need this for ContactMePage */
    overflow-x: hidden;
}

body,
html {
    font-family: "Regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin: 0;
}

.light-mode {
    min-height: 100vh;
    /* background-color: var(--cream-white); */
    color: var(--dark-gray);
}

.dark-mode {
    min-height: 100vh;
    background-color: var(--dark-gray);
    color: var(--cream-white);
}

.background-image img {
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.dark-mode button.primary-button {
    background-color: white;
    color: black;
    border: 2px solid white;
}

.dark-mode button.secondary-button {
    background-color: var(--dark-gray);
    color: white;
    border: 2px solid white;
}

.dark-mode .projects {
    background-color: var(--light-gray);
}

.light-mode *,
.dark-mode * {
    transition: all 0.25s ease-in-out;
}

/* Not too sure why adding this works for the individual project pages */
* .light-mode,
* .dark-mode {
    transition: all 0.25s ease-in-out;
}

.section-heading {
    font-size: 2.5rem;
    font-family: "Heading-Bold", serif;
    /* font-family: "Bold"; */
    text-align: center;
    padding: 2rem 0;
}

p {
    text-align: left;
    font-family: "Regular", sans-serif;
    font-size: 20px;
    line-height: 1.5;
}

@media only screen and (max-width: 1330px) {
    .primary-button,
    .secondary-button {
        width: 200px;
    }
}
