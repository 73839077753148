.footer-content {
    /* position: absolute; */
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cream-white);
    padding: 1rem 0;
}

.dark-mode .footer-content {
    background-color: var(--light-gray);
}

.footer-elements {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    align-items: center;
}

.site-logo img {
    margin-left: 4rem;
    width: 6rem;
}

.dark-mode .site-logo img {
    filter: invert(100%);
}

.footer-content .footer-links .footer-link {
    margin: 0 1.5rem;
}

.footer-content .contact-number p {
    display: flex;
    flex-direction: column;
}

.footer-content .footer-link {
    font-size: 1.2rem;
    font-family: "Light", sans-serif;
    display: inline-block;
    position: relative;
    color: var(--dark-blue);
    text-decoration: none;
}

.footer-content .footer-link:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--dark-blue);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.footer-content .footer-link:hover {
    color: var(--dark-blue);
}

.footer-content .footer-link:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.dark-mode .footer-content .footer-link {
    color: var(--salmon);
}

.dark-mode .footer-content .footer-link:after {
    background-color: var(--salmon);
}

.footer-content .contact-number .phone-subheading {
    font-family: "Bold", sans-serif;
}

.footer-content .contact-number .phone-subheading,
.footer-content .contact-number .phone-number {
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.footer-content .footer-elements .contact-number img {
    max-width: 2.5rem;
}

.footer-content .footer-elements .contact-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 4rem;
    gap: 0.5rem;
}

@media only screen and (max-width: 586px) {
    .footer-elements {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-elements .site-logo img,
    .footer-elements .contact-number {
        margin: 0.5rem 1.5rem;
    }

    .footer-elements .footer-links {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 1rem 0;
        /* margin-left: 3rem; */
        /* align-items: normal; */
    }

    .contact-number {
        margin-left: 4rem;
    }
}

@media only screen and (min-width: 587px) and (max-width: 768px) {
    .footer-elements {
        padding: 1rem 0;
        flex-direction: row;
        justify-content: space-around;
    }

    .footer-elements .site-logo img,
    .footer-elements .contact-number {
        margin: 0.5rem 1.5rem;
    }

    .footer-elements .footer-links {
        /* display: flex;
        flex-direction: column; */
        margin: 0.5rem 0;
        /* margin-left: 3rem; */
        /* align-items: normal; */
    }

    .footer-elements .footer-links .footer-link {
        margin: 0 0.5rem;
    }

    .footer-content .footer-elements .contact-number {
        margin-right: 1rem;
    }
}
