.navbar {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100vw;
}

.navbar .link a {
    text-decoration: none;
    color: var(--dark-gray);
}

.dark-mode .link a {
    color: white;
}

.homepage-logo img {
    margin-left: 8rem;
    width: 10rem;
}

.dark-mode .homepage-logo img {
    filter: invert(100%);
}

.toggle-button {
    margin-top: 2rem;
}

.links {
    display: flex;
    list-style-type: none;
    /* padding: 0 2rem; */
    margin-top: 2.5rem;
    margin-right: 6rem;
}

.link {
    padding: 0 2rem;
    /* font-family: "SemiBold"; */
    font-family: "Heading-SemiBold", serif;
    font-size: 1.2rem;
}

@media only screen and (max-width: 968px) {
    .homepage-logo img {
        margin-left: 4rem;
        /* width: 8rem; */
    }

    .link {
        padding: 0 0.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .navbar {
        justify-content: space-between;
        padding: 0;
    }

    .homepage-logo {
        display: none;
    }

    .links,
    .list-of-links {
        width: 90%;
        justify-content: space-around;
        margin: 1rem 0;
    }

    .link {
        padding: 0 1rem;
    }

    .toggle-button {
        display: none;
    }
}

@media only screen and (min-width: 769px) {
    .hide-on-desktop {
        display: none;
    }
}
