.tools {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .tools {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 400px) {
    .tools {
        grid-template-columns: auto;
    }
}
