.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.modeToggle {
    display: flex;
    position: absolute;
    scale: 0.6;
    gap: 1em;
    left: 45%;
    /* top: 45%;
    transform: rotate(90deg); */
}

.light-mode-side img,
.dark-mode-side img {
    width: 1.5rem;
    position: relative;
    top: 4px;
}

.dark-mode .light-mode-side,
.dark-mode .dark-mode-side {
    filter: invert(100%);
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--cream-white);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--light-gray);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
